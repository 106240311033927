<template>
  <div class="apply">
    <div class="content">
      <!-- 顶部 -->
      <Header :titleName="data.titleName" :return="true" />
      <div style="position: relative;padding-top: 2rem;">
        <img class="apply_msg_bg" src="@/assets/images/materialsApply/apply_msg_bg.png" alt="">
        <div class="card project_name">
          <div class="flex ">
            <img :src="data.detail.imgUrl" alt="">
            <div>
              <div class="name">{{ data.detail.title }}</div>
              <div class="f25c8" style="display: none;">{{ data.goodsDetail.goodsName }}</div>
            </div>
          </div>
          <div class="flex sum  space_between align_items_center" style="display: none;">
            <div>剩余数量 <span>{{ data.detail.minQuantity - data.detail.receiveNumber }}份</span></div>
            <div>可申领 <span>1份</span></div>
          </div>
        </div>
      </div>



      <div class="card">
        <div class="f25c8 tag">此平台为确保信息真实性，请如实填写，该信息将不会对外进行公示公开。</div>
        <div class="f33 mb50">个人信息</div>
        <div class="items space_between align_items_center">
          <div style="">姓名<div class="bt">必填</div>
          </div>
          <van-field v-model="data.postData.realName" :input-align="'right'" placeholder="请输入真实姓名" />
        </div>
        <div class="items space_between align_items_center">
          <div>性别<div class="bt">必填</div>
          </div>
          <div class="btns">
            <div :class="data.postData.sex == 0 ? 'active' : ''" @click="onChange1(0)">男</div>
            <div :class="data.postData.sex == 1 ? 'active' : ''" @click="onChange1(1)">女</div>
          </div>
        </div>
        <div class="items space_between align_items_center">
          <div style="">手机号<div class="bt">必填</div>
          </div>
          <div class="align_items_center">
            <van-field v-model="data.postData.mobile" :input-align="'right'" placeholder="请输入手机号" />
            <!-- <div style="color: #E61D18;">获取手机号</div> -->
          </div>
        </div>


        <!-- <div class="items space_between align_items_center">
          <div style="">身份证号<div class="bt">必填</div>
          </div>
          <van-field v-model="data.postData.idNumber" :input-align="'right'" placeholder="请输入身份证号" />
        </div> -->

        <div class="items space_between align_items_center">
          <div>申领{{data.dwTit}}<div class="bt">必填</div>
          </div>
          <div @click="dwDiaShow()">
            <span v-if="data.postData.idNumber" style="color: #333;">{{ data.postData.idNumber }}</span>
            <span v-else style="color: #ACACAC;">请选申领{{data.dwTit}}</span>
            <img class="right" src="@/assets/images/donate/right.png" alt="">
          </div>
        </div>


        <div class="items space_between align_items_center" v-if="data.detail.goodType == 1">
          <div>选择地区<div class="bt">必填</div>
          </div>

          <div class="align_items_center" @click="showPopup">
            <div v-if="data.addressSelected.length > 0">
              <span v-for="(item, index) in data.addressSelected" :key="index" style="margin-left: .1rem;">{{ item.name
                }}</span>
            </div>
            <span style="color: #ACACAC;" v-else>请选择地区</span>
            <img class="right" src="@/assets/images/donate/right.png" alt="">
          </div>
        </div>

        <div class="items space_between align_items_center" v-if="data.detail.goodType == 1">
          <div style="">详细地址<div class="bt">必填</div>
          </div>
          <van-field v-model="data.postData.address" :input-align="'right'" placeholder="请输入详细地址" />
        </div>

        <div class="items space_between align_items_center">
          <div style="">申领数量</div>
          <div>1份</div>
        </div>
      </div>


      <div class="card" style="display: none;">
        <div class="f33 mb50 fw6">领取信息</div>
        <div class="items space_between align_items_center">
          <div style="">领取方式</div>
          <div>{{ data.detail.applyWay }}</div>
        </div>
      </div>


      
      <div class="footer_btn">
        <div @click="submit">提交信息</div>
      </div>

      <!-- 领取成功弹窗 -->
      <div class="jj_dialog succeed" v-if="data.succeedDialog">
        <div class="succeed_view">
          <img class="succeed_img" src="@/assets/images/materialsApply/succeed.png" alt="">
          <div style="color: #888;margin-bottom: .4rem;">申领成功</div>
          <div v-if="data.detail.goodType == 2">
            <div class="btn red_btn" style="display: none;" @click="toHXMdetail">查看核销码</div>
            <div class="btn" @click="back">返回</div>
          </div>
          <div v-else>
            <div class="btn red_btn" @click="back">返回</div>
          </div>
        </div>
      </div>

      <div class="jj_dialog adressbox" v-if="data.areaShow" @click="areaCancel">
        <van-area :area-list="data.areaList" :value="data.addressValue" @confirm="areaConfirm" @cancel="areaCancel"
          @click.stop="" />
      </div>

      <!-- 申领单位 -->
      <div class="jj_dialog" v-if="data.dwDiaShow" @click="data.dwDiaShow = false">
        <div class="dialog_box" @click.stop="">
          <div class="dialog_header space_between align_items_center">
            <div @click="data.dwDiaShow = false">取消</div>
            <div class="f35 fw6">选择申领{{data.dwTit}}</div>
            <div class="cred fw6" @click="dwSelectedFn">确定</div>
          </div>
          <div class="list">
            <div v-for="(item, index) in data.dwList" :key="index"
              :class="data.dwSelectMsg == item ? 'active' : ''" @click="dwChange(item)">{{
                item }}</div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script setup>

import { reactive, toRefs, onMounted } from "vue";
import { useRoute, useRouter } from 'vue-router'
import Header from "../../components/Header.vue";
import { openAppFunction } from '../../common/js/share'
import Api from "../../utils/api";
import { Toast, Popup } from 'vant';
const route = useRoute()
const router = useRouter()


let data = reactive({
  dia: false,
  sex: 1,
  detail: {},
  areaShow: false,
  goodsDetail: {},
  addressValue: 0,
  addressSelected: [],
  succeedDialog: false,
  titleName: '我要申领',
  areaList: {
    province_list: {},
    city_list: {},
    county_list: {},
  },
  dwDiaShow: false,
  dwSelectMsg: "",
  dwTit:'单位编号',
  dwList: [
    "1",// 临沂市总工会
    "2",//五粮液集团工会委员会
    "3",//一汽集团工会
    "4",//四川
    "5",//安徽省总工会
    "6",//安徽能源化学地质工会
    "7",//安徽网络信息工会
    "8",//平顶山市总工会
    "9",//镇江市总工会
    "10",//淮北市总工会
    "11",//和顺县总工会
    "12",//壶关县总工会
    "13",//吕梁市（临县志愿者协会）
    "14",//北京城建集团工会
    "15"//北京大兴区总工会
  ],

  HXMid: 0,
  id: 0,
  adressList: {},
  postData: {
    realName: '',
    sex: 0,
    mobile: '',
    idNumber: '',
    provinceId: '',
    cityId: '',
    address: '',
  },
});

const submit = () => {
  let parmas = {}
  for (const key in data.postData) {
    if (data.postData[key]) {
      parmas[key] = data.postData[key]
    }
  }
  //姓名确认
  let chineseNameRegex = /^[\u4E00-\u9FA5\uf900-\ufa2d·s]{2,20}$/;
  if (!chineseNameRegex.test(data.postData.realName)) {
    Toast('请输入正确姓名！');
    return
  }

  // 手机号确认
  let phoneReg = /^1[3456789]\d{9}$/;
  if (!phoneReg.test(data.postData.mobile)) {
    Toast('请输入正确手机号！');
    return
  }

  //  身份证确认
  // let regExp = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
  // if (!regExp.test(data.postData.idNumber)) {
  //   Toast('请输入正确身份证号！');
  //   return
  // }

  if (data.postData.idNumber == "") {
    Toast('请选择单位编号！');
    return
  }

  parmas.sex = data.postData.sex == 1 ? '女' : '男'
  parmas.id = data.id
  if (data.detail.goodType == 1) {
    if (data.addressSelected.length == 0) {
      Toast('请选择地区！');
      return
    }
    if (!data.postData.address) {
      Toast('请输入详细地址！');
      return
    }
    parmas.provinceId = data.adressList[data.addressSelected[0].code]
    parmas.cityId = data.adressList[data.addressSelected[1].code]
    parmas.areaId = data.adressList[data.addressSelected[2].code]
  }

  Api.applyUser(parmas).then(res => {
    console.log(res)
    if (res.status == 200) {
      data.succeedDialog = true
      if (data.detail.goodType == 2) {
        data.HXMid = res.data.id
      }
    }
  })

}
const toHXMdetail = () => {

  router.push({ path: '/findApplyMsg', query: { id: data.HXMid } })
};
const areaConfirm = (event) => {
  data.areaShow = false
  data.addressValue = event[2].code
  data.addressSelected = event
  console.log(event)
  console.log(data.adressList[event[2].code])
};
const areaCancel = () => {
  data.areaShow = false
};

const back = () => {
  router.go(-1)
}

const showPopup = () => {
  data.areaShow = true
};

// 申领单位列表点击选中
const dwChange = (item) => {
  data.dwSelectMsg = item;
};

// 申领单位列表弹窗点击确定
const dwSelectedFn = () => {
  data.postData.idNumber = data.dwSelectMsg
  data.dwDiaShow = false;
};

// 申领单位列表谈弹窗显示
const dwDiaShow = () => {
  data.dwSelectMsg = data.postData.idNumber
  data.dwDiaShow = true;
};


onMounted(() => {

  data.id = route.query.id
  console.log(data.id)
  //儿童口腔筛查 换成年龄段
  if(data.id==30){
  	  data.dwList=[
  	  		"3～6岁",
  	  		"7～9岁",
  	  		"10～12岁"
  	  ];
  	  data.dwTit="年龄段";
  }
  

  Api.applyForProjectDetails(data.id).then(res => {
    if (res.status == 200) {
      data.detail = res.data
      data.goodsDetail = res.data.applyForProjectDetails[0]
      if (res.data.goodType == 1) {
        addressDataInit()
      }
    }
  })
  shareFn()

});
// 分享详情
const shareFn = () => {
  const jsonString = {
    shareUrl: window.location.href,//分享链接
    shareCorver: window.location.origin + '/logo.png',
    shareTitle: '中国职工发展基金会',
    shareDesc: data.titleName,//分享描述
  }
  console.log(jsonString)
  openAppFunction('setupShareData', { shareData: JSON.stringify(jsonString) })
}


const onChange1 = (n) => {
  data.postData.sex = n
};

// 地区数据重整
const addressDataInit = () => {
  Api.clientArea().then(res => {
    if (res.status == 200) {
      for (let i = 0; i < res.data.length; i++) {
        data.areaList.province_list[(11 + i) + '0000'] = res.data[i].title
        data.adressList[(11 + i) + '0000'] = res.data[i].id

        for (let j = 0; j < res.data[i].children.length; j++) {

          data.areaList.city_list[(11 + i) + '' + (11 + j) + '00'] = res.data[i].children[j].title
          data.adressList[(11 + i) + '' + (11 + j) + '00'] = res.data[i].children[j].id

          for (let k = 0; k < res.data[i].children[j].children.length; k++) {
            data.areaList.county_list[(11 + i) + '' + (11 + j) + '' + (11 + k)] = res.data[i].children[j].children[k].title
            data.adressList[(11 + i) + '' + (11 + j) + '' + (11 + k)] = res.data[i].children[j].children[k].id
          }
        }
      }
    }
  })
}
</script>

<style lang='less' scoped>
::v-deep input.van-field__control::-webkit-input-placeholder {
  color: #ACACAC;
  font-size: .3867rem;
}

.succeed {
  .succeed_view {
    width: 6.92rem;
    padding: .6667rem 0;
    background: #FFFFFF;
    border-radius: .2533rem;
    position: absolute;
    left: 0;
    right: 0;
    top: 30vh;
    margin: auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: .3867rem;
    min-height: 6.4rem;

    .btn {
      width: 5.64rem;
      height: 1.1333rem;
      color: #333;
      background-color: #F5F5F5;
      border-radius: .1067rem;

      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: .2667rem;
    }

    .red_btn {
      background: #E61D18 !important;
      color: #fff !important;
    }

    .succeed_img {
      width: 1.4667rem;
      margin-bottom: .4rem;

    }
  }
}

.adressbox {
  display: flex;
  align-items: flex-end;

  .van-area {
    width: 100%;
  }
}

.apply {
  display: flex;
  flex-flow: column;
  height: 100%;

  .content {
    padding-bottom: 2.3rem;

    .van-cell {
      padding-right: 0;
    }

    .items {
      border-bottom: .0133rem solid #ECECEC;
      min-height: 1.2533rem;
      font-size: .3867rem;

      .right {
        width: .16rem;
        margin-left: .2rem;
      }

      &:last-child {
        border: none;
      }

      .bt {
        width: .8267rem;
        height: .4133rem;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: .28rem;
        color: #E61D18;
        background-color: #FFF4F3;
        font-weight: 400;
        margin-left: .16rem;
      }

      .btns {
        display: flex;

        >div {
          width: 1.2267rem;
          height: .72rem;
          background-color: #f5f5f5;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: .16rem;
          border-radius: .0533rem;
        }

        img {
          width: .4667rem;
          margin-right: .1067rem;
        }

        .active {
          background-color: #E61D18;
          color: #fff;
        }
      }

      >div {
        &:first-child {
          font-weight: 600;
          display: flex;
          align-items: center;
          white-space: nowrap;
        }
      }
    }

    .apply_msg_bg {
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
    }

    .f25c8 {
      font-size: .3333rem;
      color: #888;
    }

    .f33 {
      font-size: .44rem;
    }

    .mb50 {
      margin-bottom: .6667rem;
    }

    .tag {
      margin-bottom: .4667rem;
    }

    .project_name {
      position: relative;
      z-index: 2;
      margin-top: 0 !important;
      padding-bottom: .5rem !important;

      .name {
        font-size: .44rem;
        font-weight: 600;
        margin-bottom: .2667rem;
      }

      .sum {
        font-size: .3333rem;
        color: #888;

        span {
          font-size: .4133rem;
          font-weight: 600;
          color: #333;
        }
      }

      img {
        width: 3.3067rem;
        height: 2.56rem;
        object-fit: cover;
        margin-right: .2rem;
        margin-bottom: .8rem;
        border-radius: .1067rem;
      }
    }

    .card {
      background: #FFFFFF;
      border-radius: .2533rem;
      width: 9.3867rem;
      padding: .5067rem;
      padding-bottom: 0;
      margin: 0 auto;
      margin-bottom: .4rem;
    }
  }

  .footer_btn {
    height: 2.3333rem;
    width: 100%;
    position: fixed;
    left: 0;
    bottom: 0;
    background-color: #fff;
    box-shadow: 0px -0.1333rem .08rem 1px rgba(0, 0, 0, 0.03);

    >div {
      width: 9.3867rem;
      height: 1.1333rem;
      background: #E61D18;
      border-radius: .1067rem;
      margin: .2667rem auto;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: .3867rem;
      color: #fff;
    }
  }


}


.dialog_box {
  border-radius: .5067rem .5067rem 0px 0px;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  min-height: 4rem;
  background-color: #fff;
  padding-bottom: .5rem;

  .list {
    max-height: 9.3333rem;
    overflow-y: scroll;

    >div {
      height: 1.3067rem;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: .3867rem;
      border-top: .0133rem #E7E7E8 solid;
    }

    .active {
      background-color: #F5F5F5;
    }
  }

  .dialog_header {
    padding: 0 1.0933rem;
    font-size: .3867rem;
    height: 1.7333rem;

    .f35 {
      font-size: .4667rem;
    }
  }
}
</style>